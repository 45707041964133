<template lang="pug">
#welcome-kit.step-component
  icon#logo(data="~@icon/kit-logo.svg", color="#3F3F3F #151515")
  h2 Welcome to your Home Diagnostic Kit
  icon#kit-box(data="~@icon/welcome-image.svg")
  button.button(@click="gotoNext()") Let’s go
</template>

<script>
import AmplitudeAPI from '@/utils/amplitude'

export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {
    gotoNext,
  },

  created() {
    if (typeof this.$route.query.q !== 'undefined') {
      this.$store.commit('user/SET_PHONE', this.$route.query.q)
      this.$amplitude.getInstance().setUserId(this.$route.query.q)
    }
    if (typeof this.$route.query.id !== 'undefined') {
      this.$amplitude.getInstance().setUserId(this.$route.query.id)
    }
    if (typeof this.$route.query.barcode !== 'undefined') {
      this.$amplitude.getInstance().setUserId(this.$route.query.barcode)
    }
    AmplitudeAPI.logEvent('InitiatedApp', {
        source: this.$route.query.src,
        kit_type: this.$route.query.type
      })
  },
  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */

function gotoNext() {
  this.$emit('complete')
}
</script>
